import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import moment  from 'moment';
import Layout from "../components/layout";
import SEO from "../components/seo";
import MailForm from "../components/MailForm.jsx";
// import ReformsMap from '../components/maps/ReformsMap';
// import RedistrictingTimelineAlertMap from '../components/maps/RedistrictingTimelineAlertMap';
import RedistrictingDashboardMapv2 from "../components/maps/RedistrictingDashboardMapv2.js";
// import Welcome from "../components/Welcome.jsx";
// import Feed from "../components/Feed.jsx";
// import Links from "../components/Links.jsx";

import "../styles/styles.css";
import "../styles/home.css";

const AVAILABLE_INTERACTIVE_MAPS = {
  ReportCard: "ReportCard",
  Reforms: "Reforms",
  TimelineAlert: "TimelineAlert",
};

const IndexPage = () => {
  const contextData = useStaticQuery(graphql`
    query indexPageQuery {
      allFeedMailchimp(limit: 5) {
        nodes {
          id
          pubDate
          link
          title
        }
      }
      allFeedPec(limit: 5, filter: {categories: {in: "Redistricting"}}) {
        nodes {
          id
          pubDate
          link
          contentSnippet
          creator
          title
        }
      }
    }
  `);

  const [selectedMap, SetSelectedMap] = useState(AVAILABLE_INTERACTIVE_MAPS.ReportCard);
  return (
    <Layout>
      <SEO
        title="Home"
        imagePath={`assets/images/pgp-og-main.png`}
        imageWidth={1200}
        imageHeight={630}
      />
      <div className="home-page">
        {/* <div className="breaking-content">Breaking Content</div> */}
        <div className="content">
          <div>
            <div className="content-col-content">
              <h1 className="redistricing-dashboard-title">We bridge the gap between mathematics and the law to achieve fair representation through redistricting reform</h1>   
              {/* <h2>
                <button
                  className={selectedMap === AVAILABLE_INTERACTIVE_MAPS.ReportCard ? "selected" : ""}
                  onClick={() => SetSelectedMap(AVAILABLE_INTERACTIVE_MAPS.ReportCard)}
                >
                    <h3>Redistricting Report Card</h3>
                </button>
                <button
                  className={selectedMap === AVAILABLE_INTERACTIVE_MAPS.Reforms ? "selected" : ""}
                  onClick={() => SetSelectedMap(AVAILABLE_INTERACTIVE_MAPS.Reforms)}
                >
                    <h3>State-by-state reforms</h3>
                </button>
                <button
                  className={selectedMap === AVAILABLE_INTERACTIVE_MAPS.TimelineAlert ? "selected" : ""}
                  onClick={() => SetSelectedMap(AVAILABLE_INTERACTIVE_MAPS.TimelineAlert)}
                >
                  <h3>Census-related timeline delays</h3>
                </button>
              </h2> */}
              <RedistrictingDashboardMapv2 />
              {/* {selectedMap === AVAILABLE_INTERACTIVE_MAPS.Reforms && <ReformsMap />} */}
              {/* {selectedMap === AVAILABLE_INTERACTIVE_MAPS.TimelineAlert && <RedistrictingTimelineAlertMap /> } */}
            </div>
          </div>
          <hr />
          <div className="content-col-content">
          <a className="twitter-timeline" data-width="400" data-height="600" data-dnt="true" data-theme="light" href="https://twitter.com/princetongerry?ref_src=twsrc%5Etfw">Tweets by princetongerry</a> <script async src="https://platform.twitter.com/widgets.js"></script>
          </div>
          <hr />
          <div className="content-col-content">
            <h2>
              Sign up for our <a target="_blank" rel="noopener noreferrer" href="https://us7.campaign-archive.com/home/?u=1ed63aa66c770d4c2a680227d&id=810178534b">Newsletter</a>
            </h2>
            <div className="newsletter">
              <MailForm />
              <div className="newsletter-archive">
                {
                    contextData && 
                    contextData.allFeedMailchimp.nodes.map((post, index) => {
                      return <div className="newsletter-archive-links" key={post.id}>
                          <em>{moment(post.pubDate).format("ddd MMM D, Y")}</em>  
                          <OutboundLink href={post.link}>{post.title}</OutboundLink>
                        </div>
                    })
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="content-col-content">
            <h2>
              From the <a target="_blank" rel="noopener noreferrer" href="https://election.princeton.edu">Princeton Election Consortium Blog</a>
            </h2>
            <div className="election-blog">
              {
                contextData && 
                contextData.allFeedPec.nodes && contextData.allFeedPec.nodes[0] && 
                <div className="election-blog-preview" key={contextData.allFeedPec.nodes[0].id}>
                  <em>{moment(contextData.allFeedPec.nodes[0].pubDate).format("dddd  MMMM D, Y")}</em>
                  <a target="_blank" rel="noopener noreferrer" href={contextData.allFeedPec.nodes[0].link}>
                    <h3>{contextData.allFeedPec.nodes[0].title}</h3>
                  </a>
                  <p>by {contextData.allFeedPec.nodes[0].creator}</p>
                  <p>{contextData.allFeedPec.nodes[0].contentSnippet}</p>
                </div>
              }
              <div className="election-blog-list">
              {
                contextData && 
                contextData.allFeedPec.nodes.map((post, index) => {
                  if (index === 0) {
                    return null
                  }
                  else {
                    return <div className="election-blog-links" key={post.id}>
                        <em>{moment(post.pubDate).format("ddd MMM D, Y")}</em>
                        <a target="_blank" rel="noopener noreferrer" href={post.link}>{post.title}</a>
                      </div>
                  }
                })
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;